<template>
  <div id="home">
    <div v-if="!isMaintain">
      <div v-if="isLineLogIn">
        <!-- Sec 1 -->
        <section id="sec-1" class="div-banner-2">
          <div class="banner-promotion-2">
            <b-img
              v-if="$t('pages.home.banner') === 'th'"
              alt="banner"
              :src="require('../assets/images/th2024.png')"
              class="banner-flex"
            ></b-img>
            <b-img
              v-else
              alt="banner"
              :src="require('../assets/images/en2024.png')"
              class="banner-flex"
            ></b-img>
            <div class="home-text-2">
              <h1>
                {{ $t('pages.home.maid_search') }}
              </h1>
              <h2>
                {{ $t('pages.home.high_quality_search') }}
              </h2>
              <h3>
                {{ $t('pages.home.served_more_than') }}
              </h3>
              <b-row class="justify-content-center button-row">
                <b-col cols="12" md="4" xl="3" class="mb-3">
                  <b-button
                    variant="success"
                    block
                    :to="{ name: 'BookingOneTime' }"
                    class="button-booking-2"
                  >
                    <b-img
                      :src="require('../assets/images/icons/one-time-new.svg')"
                      alt="one-time-icon"
                      class="button-booking-img-2"
                    />
                    {{ $t('commons.book_one_time') }}
                  </b-button>
                </b-col>
                <b-col cols="12" md="4" xl="3" class="mb-3">
                  <b-button
                    variant="warning"
                    block
                    :to="{ name: 'BookingMultiPackage' }"
                    class="button-booking-2"
                  >
                    <b-img
                      :src="require('../assets/images/icons/multi-package-new.svg')"
                      alt="multi-package-icon"
                      class="button-booking-img-2"
                    />{{ $t('pages.booking.purchase_multi_package') }}
                  </b-button>
                </b-col>
              </b-row>
              <b-row v-if="!isMobile" class="d-flex justify-content-center promote-section">
                <b-col
                  v-for="(item, index) in blocks"
                  :key="index"
                  cols="4"
                  sm="2"
                  class="d-flex justify-content-center align-items-center mb-4 p-1"
                >
                  <div
                    class="promote-box d-flex flex-column align-items-center justify-content-center"
                    :style="{ 'border-bottom': `5px solid ${item.color}` }"
                  >
                    <img :src="item.icon" class="mb-2 icon-container" style="height: 65%" />
                    <h5 class="promote-text text-center mb-2">
                      {{ item.title }}
                    </h5>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </section>

        <section v-if="isMobile" id="sec-9" class="d-flex justify-content-center promote-bg">
          <b-row class="justify-content-center promote-section">
            <b-col
              v-for="(item, index) in blocks"
              :key="index"
              cols="4"
              sm="2"
              class="d-flex justify-content-center align-items-center mb-4 p-0"
            >
              <div
                class="promote-box d-flex flex-column align-items-center justify-content-center"
                :style="{ 'border-bottom': `5px solid ${item.color}` }"
              >
                <img :src="item.icon" class="mb-2 icon-container" />
                <h5 class="promote-text text-center mb-2">
                  {{ item.title }}
                </h5>
              </div>
            </b-col>
          </b-row>
        </section>

        <!-- Sec 2 -->
        <section id="sec-2">
          <b-container>
            <b-row style="margin-top: 30px; margin-bottom: 40px">
              <!-- <b-col xl></b-col> -->
              <b-col cols="12" xl>
                <h2 class="maid-text">{{ $t('pages.home.maid_perdate.title') }}</h2>
                <div class="d-flex justify-content-center align-items-center">
                  <div
                    class="maid-description d-flex flex-column justify-content-center align-items-center"
                  >
                    <div class="maid-detail d-flex flex-column">
                      <div
                        class="d-flex"
                        style="gap: 8px"
                        v-for="(item, index) in maid_description"
                        :key="index"
                      >
                        <b-img
                          :src="require('../assets/images/icons/check-icon.svg')"
                          alt="check-icon"
                          class="check-icon mt-1"
                        />
                        <p>{{ item }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  src="@/assets/images/element_1.png"
                  alt="Left Top Element"
                  class="element-left-top"
                />

                <img
                  src="@/assets/images/element_2.png"
                  alt="Right Bottom Element"
                  class="element-right-bottom"
                />
              </b-col>
              <!-- <b-col xl></b-col> -->
            </b-row>
            <b-row class="align-items-end">
              <b-col xl></b-col>
              <b-col cols="12" xl>
                <h4 class="text-center text-light mb-0">
                  {{ $t('pages.home.recommended_maids') }}
                </h4>
              </b-col>
              <b-col xl class="text-right d-none d-xl-block">
                <b-link :to="{ name: 'Maids' }" class="text-light">
                  {{ $t('pages.home.see_more_maids') }} ({{ totalMaidsRecommend }}+)
                  <i class="fas fa-chevron-right"></i>
                </b-link>
              </b-col>
            </b-row>
            <b-row align-h="center">
              <slick
                class="slick-class maid-slick"
                ref="slick"
                :options="slickOptions"
                v-if="renderComponent && maids.length > 0"
              >
                <maid :detail="m" v-for="(m, i) in maids" :key="i" :disableClick="false" />
              </slick>
              <slick
                class="slick-class maid-slick"
                ref="slick"
                :options="slickOptions"
                v-show="maids.length == 0"
              >
                <LoadingMaid v-for="i in [0, 1, 2, 3]" :key="i"></LoadingMaid>
              </slick>
            </b-row>
            <b-button :to="{ name: 'Maids' }" variant="primary-dark d-xl-none" block
              >{{ $t('pages.home.see_more_maids') }}
              {{ totalMaidsRecommend > 0 ? `(${totalMaidsRecommend})+` : '...' }}</b-button
            >
          </b-container>
        </section>

        <!-- Use color style same as section 6 -->
        <section id="sec-6" v-if="isLoggedIn && friends.length > 0 && false">
          <b-container>
            <b-row class="align-items-end">
              <b-col xl cols="3"></b-col>
              <b-col cols="6">
                <h4 class="text-center mb-0">Maids rated highly by your FB friends</h4>
              </b-col>
              <b-col xl cols="3" class="text-right d-none d-xl-block">
                <b-link :to="{ name: 'Maids' }" class="text-primary">
                  {{ $t('pages.home.see_more_maids') }} ({{ totalMaidsRating }}+)
                  <i class="fas fa-chevron-right"></i>
                </b-link>
              </b-col>
            </b-row>
            <b-row align-h="center">
              <!-- <slick class="slick-class maid-slick" ref="slick" :options="slickOptions" v-if="renderComponent && maidsRating.length > 0">
                <maid :detail="m" :recommendRating="true" v-for="(m, i) in maidsRating" :key="i" />
              </slick> -->
              <slick class="slick-class maid-slick" ref="slick" :options="slickOptions">
                <LoadingMaid v-for="i in [0, 1, 2, 3]" :key="i"></LoadingMaid>
              </slick>
            </b-row>
            <div class="mb-4 d-flex justify-content-center align-items-center">
              <span>My FB Friends who use Bluuu</span>
              <div class="ml-4 pl-2">
                <b-img
                  src="https://graph.facebook.com/107766607206365/picture?type=large"
                  class="friend_avatar"
                  v-for="friend in friends"
                  :key="friend.fb_id"
                ></b-img>
                <div class="more-friend d-inline-block" v-if="friends.length > 5">
                  <span>+ {{ friends.length - 5 }}</span>
                </div>
              </div>
            </div>
            <b-button :to="{ name: 'Maids' }" variant="secondary d-xl-none" block
              >{{ $t('pages.home.see_more_maids') }} ({{ totalMaidsRating }}+)</b-button
            >
          </b-container>
        </section>

        <!-- Sec 7 -->
        <section id="sec-7" class="confetti">
          <b-container align-v="center">
            <b-row align-h="center">
              <h4
                class="text-center mt-4 mb-0"
                style="color: #005dff; font-weight: 700; background-color: white; padding: 4px"
              >
                {{ $t('pages.home.testimonials_header') }}
              </h4>
              <slick
                v-if="renderComponent"
                class="slick-class testimonial d-flex justify-content-center"
                :options="slickTestimonialOptions"
                style="width: 90%"
              >
                <div v-for="i in [0, 1, 2, 3, 4, 5, 6]" :key="i">
                  <div
                    class="testimonial-card"
                    :style="{ 'background-color': `#${testimonialColor[i % 3]}` }"
                    shadow
                  >
                    <div class="testimonial-textarea-div">
                      <div style="padding-top: 20px; padding-bottom: 20px">
                        <div class="testimonial-textarea d-flex align-items-center">
                          <p class="text-center" style="color: #757575; font-size: 16px">
                            {{ $t('pages.home.testimonials')[i].comment }}
                          </p>
                        </div>
                      </div>
                      <img
                        alt="testimonial-double"
                        class="testimonial-double double-top"
                        :src="
                          require(`../assets/images/testimonial/double/${
                            testimonialColor[i % 3]
                          }.png`)
                        "
                      />
                      <img
                        alt="testimonial-double"
                        class="testimonial-double double-bottom"
                        :src="
                          require(`../assets/images/testimonial/double/${
                            testimonialColor[i % 3]
                          }.png`)
                        "
                      />
                    </div>
                    <div
                      class="testimonial-namearea d-flex flex-column align-items-center justify-content-center"
                      style="width: 100%; height: max-content; position: relative"
                    >
                      <div class="testimonial-img-space"></div>
                      <p
                        class="text-center"
                        style="color: white; padding: 0.5rem; margin-bottom: 0.5rem"
                      >
                        {{ $t('pages.home.testimonials')[i].name }}
                      </p>
                      <div class="testimonial-img" style="border: 5px solid white">
                        <img
                          alt="testimonial-img"
                          :src="getTestimonialImgUrl($t('pages.home.testimonials')[i].name)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </slick>
            </b-row>
          </b-container>
        </section>

        <!-- Sec 3 -->
        <section id="sec-3">
          <b-container>
            <b-row>
              <b-col xl="5">
                <b-card :title="$t('pages.home.pricing')" class="card-pricing">
                  <ul class="list-unstyled list-pricing">
                    <li class="list-pricing-item">
                      <div class="list-pricing-icon">
                        <b-img
                          alt="one-time-icon"
                          :src="require('../assets/images/icons/one-time-secondary.png')"
                        ></b-img>
                      </div>
                      <div class="list-pricing-text">
                        <h5>{{ $t('pages.home.one_time') }}</h5>
                        <p>{{ $t('pages.home.can_book_little_as_2hr') }}</p>
                      </div>
                    </li>
                    <li class="list-pricing-item">
                      <div class="list-pricing-icon">
                        <b-img
                          alt="multi-package-icon"
                          :src="require('../assets/images/icons/package-secondary.png')"
                        ></b-img>
                      </div>
                      <div class="list-pricing-text">
                        <h5>{{ $t('pages.home.packages') }}</h5>
                        <p>
                          {{ $t('pages.home.can_book_up_to_28x_with_a_discount') }}
                        </p>
                      </div>
                    </li>
                  </ul>
                  <b-button :to="{ name: 'Pricing' }" variant="secondary" block>{{
                    $t('pages.home.see_pricing')
                  }}</b-button>
                </b-card>
              </b-col>
            </b-row>
          </b-container>
        </section>

        <!-- Sec 4 -->
        <section id="sec-4">
          <b-container>
            <!-- Covid Section -->
            <!-- <div class="collapse-question covid">
              <b-link
                class="collapse-header"
                v-b-toggle.collapse-covid
                v-html="$t('pages.home.questions.is_it_safe_to_use_during_covid-19')"
              ></b-link>
              <b-collapse :visible="isVisible" id="collapse-covid">
                <b-row>
                  <b-col cols="12">
                    <b-card style="min-height: 100px">
                      <div class="d-flex w-100 h-100">
                        <b-card-title title-tag="h5" class="text-center my-auto">
                          {{
                            $t(
                              'pages.home.questions.here_is_what_we_are_doing_to_minimize_the_risk'
                            )
                          }}
                        </b-card-title>
                      </div>
                    </b-card>
                  </b-col>
                  <b-col lg="6" xl="4">
                    <b-card>
                      <div>
                        <div class="text-center">
                          <b-img
                            :src="require('../assets/images/main_icon/0-1.svg')"
                            class="mb-3 mr-0"
                          ></b-img>
                          <b-card-title title-tag="h5" class="text-center">1</b-card-title>
                          <p>
                            {{ $t('pages.home.questions.covid-desc-1') }}
                          </p>
                        </div>
                      </div>
                    </b-card>
                  </b-col>
                  <b-col lg="6" xl="4">
                    <b-card>
                      <div>
                        <div class="text-center">
                          <b-img
                            :src="require('../assets/images/main_icon/0-2.svg')"
                            class="mb-3 mr-0"
                          ></b-img>
                          <b-card-title title-tag="h5" class="text-center">2</b-card-title>
                          <p>
                            {{ $t('pages.home.questions.covid-desc-2') }}
                          </p>
                        </div>
                      </div>
                    </b-card>
                  </b-col>
                  <b-col lg="6" xl="4">
                    <b-card>
                      <div>
                        <div class="text-center">
                          <b-img
                            :src="require('../assets/images/main_icon/0-3.svg')"
                            class="mb-3 mr-0"
                          ></b-img>
                          <b-card-title title-tag="h5" class="text-center">3</b-card-title>
                          <p>
                            {{ $t('pages.home.questions.covid-desc-3') }}
                          </p>
                        </div>
                      </div>
                    </b-card>
                  </b-col>
                  <b-col lg="6" xl="4">
                    <b-card>
                      <div>
                        <div class="text-center">
                          <b-img
                            :src="require('../assets/images/main_icon/0-4.svg')"
                            class="mb-3 mr-0"
                          ></b-img>
                          <b-card-title title-tag="h5" class="text-center">4</b-card-title>
                          <p>
                            {{ $t('pages.home.questions.covid-desc-4') }}
                          </p>
                        </div>
                      </div>
                    </b-card>
                  </b-col>
                  <b-col lg="6" xl="4">
                    <b-card>
                      <div>
                        <div class="text-center">
                          <b-img
                            :src="require('../assets/images/main_icon/0-5.svg')"
                            class="mb-3 mr-0"
                          ></b-img>
                          <b-card-title title-tag="h5" class="text-center">5</b-card-title>
                          <p>
                            {{ $t('pages.home.questions.covid-desc-5') }}
                          </p>
                        </div>
                      </div>
                    </b-card>
                  </b-col>
                  <b-col lg="6" xl="4">
                    <b-card>
                      <div>
                        <div class="text-center">
                          <b-img
                            :src="require('../assets/images/main_icon/0-6.svg')"
                            class="mb-3 mr-0"
                          ></b-img>
                          <b-card-title title-tag="h5" class="text-center">6</b-card-title>
                          <p>
                            {{ $t('pages.home.questions.covid-desc-6') }}
                          </p>
                        </div>
                      </div>
                    </b-card>
                  </b-col>
                  <b-col cols="12">
                    <b-card style="min-height: 100px">
                      <div
                        class="d-flex w-100 h-100 p-4 flex-column align-items-center align-items-lg-start flex-lg-row"
                      >
                        <i
                          class="fas fa-exclamation-circle mb-3 mr-md-3 mt-2"
                          style="font-size: 30px"
                        ></i>
                        <p class="mb-0 text-center text-md-left">
                          {{ $t('pages.home.questions.covid-desc-7') }}
                        </p>
                      </div>
                    </b-card>
                  </b-col>
                </b-row>
              </b-collapse>
            </div> -->
            <!-- Why use Bluuu -->
            <div class="collapse-question primary">
              <b-link class="collapse-header" v-b-toggle.collapse-2>{{
                $t('pages.home.questions.why_bluuu')
              }}</b-link>
              <b-collapse :visible="isVisible" id="collapse-2">
                <b-row>
                  <b-col lg="6" xl="4">
                    <b-card>
                      <b-card-title title-tag="h5" class="text-center">
                        {{ $t('pages.home.questions.rigorous_screenings') }}
                      </b-card-title>
                      <div>
                        <div class="text-center card-top">
                          <b-img :src="require('../assets/images/main_icon/1-1.svg')"></b-img>
                          <p>
                            {{ $t('pages.home.questions.maids_have_passed_screening') }}
                          </p>
                        </div>
                        <p>
                          {{ $t('pages.home.questions.we_are_the_only_maid_platform_in_thailand') }}
                        </p>
                        <ul>
                          <li>
                            {{ $t('pages.home.questions.interviews_maids_before_accepting') }}
                          </li>
                          <li>
                            {{ $t('pages.home.questions.reject_any_kind_of_criminal_records') }}
                          </li>
                        </ul>
                      </div>
                    </b-card>
                  </b-col>
                  <b-col lg="6" xl="4">
                    <b-card>
                      <b-card-title title-tag="h5" class="text-center">
                        {{ $t('pages.home.questions.comprehensive_trainings') }}
                      </b-card-title>
                      <div>
                        <div class="text-center card-top">
                          <b-img :src="require('../assets/images/main_icon/1-2.svg')"></b-img>
                          <p>
                            {{ $t('pages.home.questions.maids_have_passed_comprehensive') }}
                          </p>
                        </div>
                        <ul>
                          <li>
                            {{ $t('pages.home.questions.passed_our_original_japanese_standard') }}
                          </li>
                          <li>
                            {{ $t('pages.home.questions.passed_our_continuous_training_programs') }}
                          </li>
                        </ul>
                      </div>
                    </b-card>
                  </b-col>
                  <b-col lg="6" xl="4">
                    <b-card>
                      <b-card-title title-tag="h5" class="text-center">
                        {{ $t('pages.home.questions.technological_excellence') }}
                      </b-card-title>
                      <div>
                        <div class="text-center card-top">
                          <b-img :src="require('../assets/images/main_icon/1-3.svg')"></b-img>
                          <p>
                            {{ $t('pages.home.questions.both_of_service_and_technology_company') }}
                          </p>
                        </div>
                        <ul>
                          <li>
                            {{ $t('pages.home.questions.our_company_mission') }}
                          </li>
                          <li>
                            {{ $t('pages.home.questions.our_founder_was_inspired_by_google') }}
                          </li>
                        </ul>
                      </div>
                    </b-card>
                  </b-col>
                </b-row>
              </b-collapse>
            </div>

            <!-- Why use a platform -->
            <div class="collapse-question warning">
              <b-link class="collapse-header" v-b-toggle.collapse-1>{{
                $t('pages.home.questions.why_book_through_us_and_not_directly')
              }}</b-link>
              <b-collapse :visible="isVisible" id="collapse-1">
                <b-row align-h="center">
                  <b-col lg="6" xl="3">
                    <b-card>
                      <div class="text-center">
                        <b-img :src="require('../assets/images/main_icon/2-1.svg')"></b-img>
                        <p v-html="$t('pages.home.questions.book_only_when_you_need')"></p>
                      </div>
                    </b-card>
                  </b-col>
                  <b-col lg="6" xl="3">
                    <b-card>
                      <div class="text-center">
                        <b-img :src="require('../assets/images/main_icon/2-2.svg')"></b-img>
                        <p>
                          {{
                            $t(
                              'pages.home.questions.try_different_maids_until_you_find_your_perfect_maid'
                            )
                          }}
                        </p>
                      </div>
                    </b-card>
                  </b-col>
                  <b-col lg="6" xl="3">
                    <b-card>
                      <div class="text-center">
                        <b-img :src="require('../assets/images/main_icon/2-3.svg')"></b-img>
                        <p v-html="$t('pages.home.questions.strict_recruiting_process')"></p>
                      </div>
                    </b-card>
                  </b-col>
                  <b-col lg="6" xl="3">
                    <b-card>
                      <div class="text-center">
                        <b-img :src="require('../assets/images/main_icon/2-4.svg')"></b-img>
                        <p>
                          {{ $t('pages.home.questions.comprehensive_trainings_2') }}
                        </p>
                      </div>
                    </b-card>
                  </b-col>
                </b-row>
              </b-collapse>
            </div>

            <!-- How we work -->
            <div class="collapse-question success">
              <b-link class="collapse-header" v-b-toggle.collapse-3>{{
                $t('pages.home.questions.how_to_use')
              }}</b-link>
              <b-collapse :visible="isVisible" id="collapse-3">
                <b-row align-h="between">
                  <b-col md="3" sm="4">
                    <div
                      class="tab-button"
                      :class="{ active: currentTab === 1 }"
                      @click="onSelectTab(1)"
                    >
                      <span>
                        <b-img
                          :src="require('../assets/images/main_icon/book-open-icon.svg')"
                        ></b-img>
                        {{ $t('pages.home.questions.booking') }}
                      </span>
                    </div>
                  </b-col>
                  <b-col md="3" sm="4">
                    <div
                      class="tab-button"
                      :class="{ active: currentTab === 2 }"
                      @click="onSelectTab(2)"
                    >
                      <span>
                        <b-img
                          :src="require('../assets/images/main_icon/calendar-icon.svg')"
                        ></b-img>
                        {{ $t('pages.home.questions.change_schedule') }}
                      </span>
                    </div>
                  </b-col>
                  <b-col md="3" sm="4">
                    <div
                      class="tab-button"
                      :class="{ active: currentTab === 3 }"
                      @click="onSelectTab(3)"
                    >
                      <span>
                        <b-img
                          :src="require('../assets/images/main_icon/maid-cleaning-icon.svg')"
                        ></b-img>
                        {{ $t('pages.home.questions.service') }}
                      </span>
                    </div>
                  </b-col>
                </b-row>
                <b-card v-show="currentTab === 1">
                  <div class="booking-container">
                    <div class="booking-item">
                      <b-img
                        class="img-display"
                        :src="require('../assets/images/main_icon/3-1-1.svg')"
                      ></b-img>
                      <h5>1</h5>
                      <p
                        v-html="$t('pages.home.questions.book_a_date_time_convenient_for_you')"
                      ></p>
                    </div>
                    <div class="booking-item">
                      <div class="top">
                        <b-img :src="require('../assets/images/main_icon/arrow-right.svg')"></b-img>
                      </div>
                    </div>
                    <div class="booking-item">
                      <b-img
                        class="img-display"
                        :src="require('../assets/images/main_icon/3-1-2.svg')"
                      ></b-img>
                      <h5>2</h5>
                      <p v-html="$t('pages.home.questions.choose_a_maid')"></p>
                    </div>
                    <div class="booking-item">
                      <div class="top">
                        <b-img :src="require('../assets/images/main_icon/arrow-right.svg')"></b-img>
                      </div>
                    </div>
                    <div class="booking-item">
                      <b-img
                        class="img-display"
                        :src="require('../assets/images/main_icon/3-1-3.svg')"
                      ></b-img>
                      <h5>3</h5>
                      <p v-html="$t('pages.home.questions.booking_is_confirmed')"></p>
                    </div>
                    <div class="booking-item">
                      <div class="top">
                        <b-img :src="require('../assets/images/main_icon/arrow-right.svg')"></b-img>
                      </div>
                    </div>
                    <div class="booking-item">
                      <b-img
                        class="img-display"
                        :src="require('../assets/images/main_icon/3-1-4.svg')"
                      ></b-img>
                      <h5>4</h5>
                      <p v-html="$t('pages.home.questions.maid_accepts_and_your_booking')"></p>
                    </div>
                  </div>
                </b-card>
                <b-card v-show="currentTab === 2">
                  <div class="booking-container">
                    <div class="booking-item">
                      <img :src="require('../assets/images/main_icon/3-2-1.svg')" />
                      <h5>{{ $t('pages.home.questions.change_schedule') }}</h5>
                      <p
                        v-html="$t('pages.home.questions.contact_maid_if_you_need_to_change_date')"
                      ></p>
                    </div>
                    <div class="booking-item">
                      <img :src="require('../assets/images/main_icon/3-2-2.svg')" />
                      <h5>
                        {{ $t('pages.home.questions.cancel_appointment') }}
                      </h5>
                      <p v-html="$t('pages.home.questions.you_can_also_cancel_your_order')"></p>
                    </div>
                  </div>
                </b-card>
                <b-card v-show="currentTab === 3">
                  <div class="booking-container">
                    <div class="booking-item">
                      <img :src="require('../assets/images/main_icon/3-3-1.svg')" />
                      <h5>1</h5>
                      <p>
                        {{ $t('pages.home.questions.maid_arrived_at_your_home') }}
                      </p>
                    </div>
                    <div class="booking-item">
                      <div class="top">
                        <img :src="require('../assets/images/main_icon/arrow-right.svg')" />
                      </div>
                    </div>
                    <div class="booking-item">
                      <b-img :src="require('../assets/images/main_icon/3-3-2.svg')" />
                      <h5>2</h5>
                      <p v-html="$t('pages.home.questions.leave_your_feedback')"></p>
                    </div>
                  </div>
                </b-card>
              </b-collapse>
            </div>
          </b-container>
        </section>

        <section id="sec-6" style="display: none">
          <b-container>
            <b-row class="align-items-end">
              <b-col xl></b-col>
              <b-col cols="12" xl>
                <h4 class="text-center mb-0">Recommended Articles</h4>
              </b-col>
              <b-col xl class="text-right d-none d-xl-block">
                <b-link :to="{ name: 'Maids' }" class="text-dark">
                  See more articles ({{ totalMaidsRating }}+)
                  <i class="fas fa-chevron-right"></i>
                </b-link>
              </b-col>
            </b-row>
            <slick ref="slick" :options="slickOptions" v-if="renderComponent && maids.length > 0">
              <article-card v-for="i in 4" :key="i" />
            </slick>
            <b-button :to="{ name: 'Maids' }" variant="primary-dark d-xl-none" block
              >See more articles ({{ totalMaidsRating }}+)</b-button
            >
          </b-container>
        </section>

        <!-- Sec 8 -->
        <section id="sec-8" class="mb-4">
          <Articles v-if="renderComponent" type="customer"></Articles>
        </section>

        <!-- Sec 5 -->
        <section id="sec-5" v-if="$i18n.locale === 'th'">
          <b-container fluid>
            <b-row no-gutters>
              <b-col cols="12" md="6" xl="6">
                <b-img
                  alt="maid-cleaning"
                  :src="require('../assets/images/maid_cleaning_new1.jpg')"
                  class="w-100"
                  style="height: 100%; object-fit: cover"
                ></b-img>
              </b-col>
              <b-col cols="12" md="6" xl="6" class="maid-cleaning-2">
                <div class="p-4">
                  <!-- <h4 class="text-white">
                    {{ $t('pages.home.be_a_partner_with_bluuu') }}
                  </h4>
                  <p class="text-light">
                    {{ $t('pages.home.receive_free_trainings') }}
                  </p> -->
                  <b-img
                    alt="สมัครเป็นแม่บ้าน Bluuu สมัครงาน ฟรี งานมี อิสระ ได้รับค่าตอบแทนที่มากกว่าเดิม"
                    :src="require('../assets/images/maid_cleaning_new3.png')"
                    class="w-100"
                  ></b-img>
                  <div class="align-items-center d-flex justify-content-center">
                    <b-link :to="{ name: 'Recruitment' }" class="button-apply-for-maid">
                      <b-button variant="success" class="button-apply-for-maid">
                        <i class="fas fa-chevron-right ml-2"></i>
                        {{ $t('pages.home.apply_for_maid') }}
                        <b-img
                          alt="apply for maid"
                          :src="require('../assets/images/maid_cleaning_new4.png')"
                          class="apply-for-maid-click"
                        ></b-img>
                      </b-button>
                    </b-link>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </section>

        <section id="sec-4_5" style="margin-top: 20px; margin-bottom: 20px">
          <b-container>
            <div class="text-center">
              {{ $t('pages.home.dbd_registered') }}
            </div>
            <a
              href="https://www.trustmarkthai.com/callbackData/popup.php?data=76b4273-27-6-d084db7b0be9d5fb23cd119052740253efe4dbb&markID=firstmar"
              target="_blank"
              rel="noopener noreferrer"
              class="d-block"
            >
              <img
                width="200px"
                style="margin: 0 auto; display: block"
                :src="require('../assets/images/DBD-Verified_silver.jpg')"
                alt="DBD_Registered"
                class="my-4"
              />
            </a>
          </b-container>
        </section>
      </div>
      <div v-else>
        <b-row class="justify-content-center" style="height: 80vh">
          <LoadingSpinner label="Loading..." style="margin: auto; height: 80px"></LoadingSpinner>
        </b-row>
      </div>
    </div>
    <div v-else>
      <!-- <b-container> -->
      <b-img
        v-if="$t('pages.home.banner') === 'th'"
        class="w-100"
        :src="require('../assets/images/maintenance_th.png')"
      ></b-img>
      <b-img v-else class="w-100" :src="require('../assets/images/maintenance_en.png')"></b-img>
      <!-- </b-container> -->
    </div>
  </div>
</template>

<script>
import { Vue, Component, Watch } from 'vue-property-decorator'
import VueContentLoading from 'vue-content-loading'
import { createNamespacedHelpers } from 'vuex'
import ApiClient from '../services'
import Maid from '../components/Maid'
import ArticleCard from '../components/ArticleCard'
import LoadingSpinner from '../components/LoadingSpinner'
import Articles from '../components/Articles'
import { PAYSOLUTION_URL , getProductDetailFromType } from '../config/constants'
import { post } from '../utils'
import Slick from 'vue-slick'
// import { requestForGetLocation } from "../utils";
// import Constant from "../config/booking";
// import { isUATEnv } from './../helpers/network'
import liff from '@line/liff'
// import { createMeta } from '../config/seo'
import LoadingMaid from '../components/LoadingMaid.vue'

const AuthStore = createNamespacedHelpers('auth')
const BookingStore = createNamespacedHelpers('booking')
const UserStore = createNamespacedHelpers('user')
@Component({
  components: {
    Maid,
    Slick,
    ArticleCard,
    VueContentLoading,
    LoadingMaid,
    LoadingSpinner,
    Articles,
  },
  computed: {
    ...AuthStore.mapState(['isLoggedIn']),
    ...UserStore.mapState(['image_url', 'is_verified_phone']),
    blocks() {
      return [
        {
          icon: require('@/assets/images/checked_crime.png'),
          title: this.$t('pages.home.promote_section.crime_checked'),
          color: '#FA560B',
        },
        {
          icon: require('@/assets/images/best_quality.png'),
          title: this.$t('pages.home.promote_section.quality_passed'),
          color: '#F3B739',
        },
        {
          icon: require('@/assets/images/maid_experience.png'),
          title: this.$t('pages.home.promote_section.experience_maid'),
          color: '#7DAA35',
        },
        {
          icon:
            this.$i18n.locale === 'th'
              ? require('@/assets/images/promotion_price.png')
              : require('@/assets/images/promotion_price_en.png'),
          title: this.$t('pages.home.promote_section.discount_off'),
          color: '#FA560B',
        },
        {
          icon: require('@/assets/images/map_location.png'),
          title: this.$t('pages.home.promote_section.region_map'),
          color: '#F3B739',
        },
        {
          icon: require('@/assets/images/admin_service.png'),
          title: this.$t('pages.home.promote_section.service_team'),
          color: '#7DAA35',
        },
      ]
    },
    maid_description() {
      return [
        this.$t('pages.home.maid_perdate.detail_1'),
        this.$t('pages.home.maid_perdate.detail_2'),
        this.$t('pages.home.maid_perdate.detail_3'),
      ]
    },
  },
  methods: {
    ...AuthStore.mapActions(['signInLine']),
    ...AuthStore.mapMutations(['ISLINELOGIN']),
    ...UserStore.mapActions(['getUserProfile']),
    ...BookingStore.mapMutations(['setPaymentBody', 'setBookingReview', 'setReduce']),
    ...BookingStore.mapActions(['processBooking_log', 'preBooking']),
  },
  // metaInfo() {
  //   return createMeta({ lang: this.$i18n.locale })
  // },
  watch: {
    '$i18n.locale'() {
      console.log('changed')
      this.rerender()
    },
  },
})
export default class Home extends Vue {
  currentTab = 1
  lineId = null
  lineToken = null
  friends = []
  maids = []
  isLineLogIn = true
  isPc = false
  isLine = false
  isMobile = false
  uatHosts = [
    'uat.bluuu.co',
    'bluuu-demo.web.app',
    'bluuu-dev-front.web.app',
    'localhost',
    '192.168.1.108',
    '192.168.1.110',
    '192.168.43.33',
    '192.168.0.201',
    '192.168.0.203',
  ]
  isMaintain = false
  totalMaidsRecommend = 0
  maidsRating = []
  totalMaidsRating = 0
  imgbanner = null
  slickOptions = {
    slidesToShow: 4,
    arrows: true,
    dots: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          dots: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          dots: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          dots: false,
          centerMode: false,
        },
      },
    ],
  }

  slickTestimonialOptions = {
    slidesToShow: 2,
    arrows: true,
    dots: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  prevRoute = {
    name: '',
  }

  testimonialColor = ['E86B2F', 'F5BF41', '88B33C']
  renderComponent = true

  isVisible = (window.__prerender && window.__prerender.prerendering) || false

  async rerender() {
    this.renderComponent = false

    await this.$nextTick()

    this.renderComponent = true
  }

  getTestimonialImgUrl(filename) {
    try {
      return require(`../assets/images/testimonial/${filename}.jpg`)
    } catch (_) {
      return require('../assets/images/testimonial/default.png')
    }
  }

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  }

  async mounted() {
    if (this.$route.name == 'index' && !this.isVisible) {
      this.$router.replace({ path: this.$i18n.locale, query: this.$route.query })
    }
    screen.width < 1199 ? (this.isPC = false) : (this.isPc = true)
    // await this.lineLoginWithLineToken();
    const toRouteName = this.$route.query.toRouteName
    if (toRouteName) {
      this.$root.$emit('bv::show::modal', 'require-auth-modal')
    }
    // else{
    //   this.$root.$emit('bv::hide::modal', 'require-auth-modal')
    // }
    // console.log('prev', this.prevRoute.name);
    // if(this.prevRoute.name == 'BookingOneTime') {
    //   this.$root.$emit('bv::hide::modal', 'require-auth-modal')
    // }
    await this.init()
  }

  async lineLoginWithLineToken() {
    // let hostUrlHref = location.href;
    if (this.isLineRedirect && !this.isLoggedIn) {
      this.isLineLogIn = false
      await liff
        .init({
          liffId: this.liffId,
        })
        .then((res) => {
          if (liff.isLoggedIn()) {
            console.log('res', res)
          }
        })
        .then(async () => {
          let payload = {}

          const accessToken = liff.getAccessToken()
          const line_id_token = liff.getIDToken()
          payload.accessToken = accessToken
          payload.line_id_token = line_id_token
          await liff
            .getProfile()
            .then(function (profile) {
              console.log('profile:::', profile.userId)
              let lineId = profile.userId
              payload.lineId = lineId
            })
            .catch((e) => {
              console.log("can't get profile", e)
              liff.init({
                liffId: this.liffId,
              })
            })
            .then(async () => {
              console.log('payload', payload)
              this.signInLine(payload)
            })
            .then(() => {
              console.log('ok reset state after login')
              this.$store.commit('booking/resetState')
            })
            .catch((e) => {
              //  liff.login();
              liff.init({
                liffId: this.liffId,
              })
              console.log(e)
              this.isLine = false
            })
        })
    } else {
      await this.$store.commit('booking/resetState')
      console.log('ok reset state no login')
    }
    await this.$store.commit('maid/resetMaidsList')

    // this.isLoggedIn && (await this.getMaidRecommendedRating())
    await this.getMaidRecommended()
  }
  async init() {
    if (this.isLineRedirect && !this.isLoggedIn) {
      this.isLineLogIn = false
      liff.ready.then(() => {
        if (!liff.isLoggedIn) {
          alert(
            'To get an access token, you need to be logged in. Please tap the "login" button below and try again.'
          )
          liff.login()
        }
      })
      await liff
        .init({ liffId: this.liffId })
        .then(async () => {
          try {
            let accessToken = await liff.getAccessToken()
            let line_id_token = await liff.getIDToken()
            let profile = await liff.getProfile()
            // console.log("profile lineId",profile.userId)
            let payload = {
              accessToken: accessToken,
              line_id_token: line_id_token,
              lineId: profile.userId,
            }

            this.signInLine(payload)
          } catch (e) {
            console.log('error', { e })
            console.log(e.message)
            // Error: LiffId is not found. or Error: The permission is not in LIFF app scope.
            if (e.message.includes('LiffId is not found')) {
              alert(
                'เกิดปัญหากับการล็อคอินไลน์ กรุณาติดต่อฝ่ายบริการลูกค้า bluuu\r\nLINE login default browser error. Please contact bluuu customer service.'
              )
            }
          }
        })
        .then(() => {
          console.log('ok reset state after login')
          this.$store.commit('booking/resetState')
        })
    } else {
      await this.$store.commit('booking/resetState')
      console.log('ok reset state no login')
    }
    await this.$store.commit('maid/resetMaidsList')
    // ;(await this.isLoggedIn) && (await this.getMaidRecommendedRating())
    await this.getMaidRecommended()
  }
  get isUATHost() {
    let host = window.location.href
    let isUAT = false

    if (this.uatHosts.some((h) => host.includes(h))) {
      isUAT = true
    }
    return isUAT
  }
  get liffId() {
    if (this.isUATHost) {
      return '1655557892-VZMnBojD'
    }
    return '1655557900-GBd2VYj9'
  }

  async getUserLocation() {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const userLocation = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            }
            resolve(userLocation)
          },
          (error) => {
            console.error('Error getting location:', error)
            reject(error)
          }
        )
      } else {
        console.error('Geolocation is not supported by this browser.')
        reject(new Error('Geolocation is not supported by this browser.'))
      }
    })
  }

  async getMaidRecommended() {
    try {
      let options = {}
      try {
        const userLocation = await this.getUserLocation()
        options.location_latitude = userLocation.latitude
        options.location_longitude = userLocation.longitude
        options.ignore_distance = true
      } catch (error) {
        console.error('Could not get user location:', error)
      }
      const result = await ApiClient.maidList(options)
      console.log('maid result', result)
      this.maids = result.data.maids.filter((maid) => maid.user)
      console.log('maid', this.maids, result.data.item_count)

      this.totalMaidsRecommend = result.data.item_count
    } catch (e) {
      console.log(e)
    }
  }

  async getMaidRecommendedRating() {
    try {
      const result = await ApiClient.maidRecommendedRating()
      this.maidsRating = result.data.maids.filter((maid) => maid.friends.length > 0)
      this.totalMaids = result.data.item_count
    } catch (e) {
      console.log(e)
    }
  }
  async getFriends() {
    try {
      const result = await ApiClient.getFriends()
      if (result.data) {
        this.friends = result.data.friends
      }
    } catch (e) {
      console.log(e.response)
    }
  }

  moveTo(element) {
    this.$scrollTo(element, 1100, {
      easing: 'ease-in-out',
      offset: -70,
    })
  }
  @Watch('isLoggedIn')
  async onUserLoggedIn() {
    if (this.isLoggedIn) {
      await this.getFriends()
      // await this.getMaidRecommendedRating()
      const toRouteName = this.$route.query.toRouteName
      if (toRouteName) {
        const fullPath = localStorage.getItem('redirectLogin')
        this.$router.push(fullPath)
        localStorage.removeItem('redirectLogin')
      }
    }
  }

  onSelectTab(index) {
    this.currentTab = index
  }

  get isLineRedirect() {
    let ans = false
    let j = window.location.search.substr(1)
    if (j.includes('code') || j.includes('liffClientId')) {
      ans = true
    }
    return ans
  }

  @Watch('image_url')
  async hasImageProfile() {
    if (this.isLineRedirect) {
      this.isLine = true
      this.ISLINELOGIN(this.isLine)
    }

    if (this.image_url && this.isLine) {
      if (localStorage.redirectTo.includes('/booking-review')) {
        await this.getUserProfile()
        let paymentBody = null
        let booking_detail_local = localStorage.booking_detail
        booking_detail_local = JSON.parse(booking_detail_local)
        if (!this.is_verified_phone) {
          paymentBody = localStorage.bodyPaySolution
          paymentBody = JSON.parse(paymentBody)
          paymentBody.customeremail = this.$store.state.user.email || 'noemail@bluuu.co'
          window.localStorage.setItem('payment_body', JSON.stringify(paymentBody))
          console.log('aaaa')

          this.setBookingReview(booking_detail_local)
          this.setPaymentBody(paymentBody)
          this.$router.push({ name: 'VerifyPhone' })
        } else {
          if (localStorage.paymentType === 'credit') {
            // let x = localStorage.redirectTo;
            localStorage.redirectTo = null
            paymentBody = localStorage.bodyPaySolution
            paymentBody = JSON.parse(paymentBody)
            paymentBody.customeremail = this.$store.state.user.email || 'noemail@bluuu.co'
            // localStorage.bodyPaySolution = paymentBody;
            // this.setPaymentBody
            window.localStorage.setItem('payment_body', JSON.stringify(paymentBody))

            if (
              typeof booking_detail_local.coupon_body === 'object' &&
              booking_detail_local.coupon_body
            ) {
              localStorage.isRedirect = 'true'
              this.$router.push(localStorage.redirectTo)
              return
            }
            const result_2 = await this.preBooking(false)
            console.log('result PAY ', result_2.data)
            if (result_2.data.id) {
              const confirmPayment = {
                booking_id: result_2.data.id,
                ref_no: paymentBody.refno,
              }
              localStorage.confirmPayment = JSON.stringify(confirmPayment)
            }
            // this.preBooking(false).then(() => {
            post(PAYSOLUTION_URL, {
              ...paymentBody,
              productdetail: getProductDetailFromType(paymentBody.productdetail)
            })
            // });
          } else {
            console.log('aaaa')
            localStorage.isRedirect = 'true'

            this.$router.push(localStorage.redirectTo)
          }
        }
      }
      this.isLineLogIn = true
    }
  }

  created() {
    this.checkScreenSize()
    window.addEventListener('resize', this.checkScreenSize)
  }
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenSize)
  }
  checkScreenSize() {
    this.isMobile = window.matchMedia('(max-width: 1024px)').matches
  }
}
</script>

<style lang="scss" scoped>
.btn-scroll {
  // margin-left: 3.3em;
  margin-top: 1em;
}
.div-banner {
  height: 100vh;
  background-image: url('../assets/images/bg-test.jpg');
  background-size: cover;
  // background-position: right;
}

// new banner 2024
.div-banner-2 {
  height: 100vh;
  background-image: url('../assets/images/bg-new3-2.png');
  background-size: cover;
  background-position: right;
  min-height: 60vw;
  max-height: 66.3vw;

  @media screen and (max-width: 1024px) {
    background-image: url('../assets/images/bg-new3.jpg');
  }

  @media screen and (max-width: 480px) {
    background-image: url('../assets/images/bg-new1.jpg');
  }
}

.banner-promotion-2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    width: 98%;
    height: 40%;
    margin-top: 7%;
    padding-right: 7%;
    padding-left: 7%;
    object-fit: contain;
  }
}

.home-text-2 {
  font-weight: 700;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 5px;
  h1 {
    font-size: 40px;
    color: #005dff;
    text-shadow: 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff,
      0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff,
      0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff,
      0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff,
      0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff,
      0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff,
      0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff,
      0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff,
      0 0 7px #ffffff;
  }
  h2 {
    font-size: 28px;
    color: #ff8706;
    text-shadow: 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff,
      0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff,
      0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff,
      0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff,
      0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff,
      0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff,
      0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff,
      0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff,
      0 0 7px #ffffff;
  }
  h3 {
    font-size: 30px;
    color: #ff8706;
    text-shadow: 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff,
      0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff,
      0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff,
      0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff,
      0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff,
      0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff,
      0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff,
      0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff,
      0 0 7px #ffffff;
  }
}

.button-row {
  margin-top: 40px;
}

.button-booking-2 {
  width: 330px;
  margin: 0 auto;
  border-radius: 9999px;
  font-weight: 500;
  font-size: 22px;
  position: relative;
  padding-left: 45px;
  box-shadow: 4px 6px 0px -2px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 4px 6px 0px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 4px 6px 0px -2px rgba(0, 0, 0, 0.75);
}

.button-booking-img-2 {
  position: absolute;
  margin-top: 0 !important;
  left: -2px;
  top: -6px;
  height: 65px !important;
  width: 65px !important;
  padding: 0 !important;
}

@media screen and (max-width: 1199px) {
  .div-banner-2 {
    background-position: 85% 100%;
  }

  .home-text-2 {
    max-width: 100%;
    margin-top: 50px;
    h1 {
      font-size: 35px;
    }
    h2 {
      font-size: 25px;
    }
    h3 {
      font-size: 28px;
    }
  }

  .banner-promotion-2 {
    img {
      padding-right: 5%;
      padding-left: 5%;
      margin-top: 5%;
      height: 45%;
      width: 80%;
    }
  }

  .button-row {
    margin-top: 40px;
  }
  .button-booking-2 {
    width: 250px;
    font-size: 18px;
  }
  .button-booking-img-2 {
    left: -15px;
    top: -6px;
    height: 55px !important;
    width: 55px !important;
  }
}

@media screen and(min-width: 1024px) {
  .div-banner-2 {
    min-height: 81vw;
  }
  .banner-promotion-2 {
    img {
      width: 98%;
      height: 35%;
      margin-top: 9%;
    }
  }
  .button-booking-2 {
    width: 280px;
    font-size: 20px;
  }
  .button-booking-img-2 {
    left: -3px;
    height: 50px !important;
    width: 50px !important;
  }
  .home-text-2 {
    // margin-top: 30px;
    h1 {
      font-size: 35px;
    }
    h2 {
      font-size: 28px;
    }
    h3 {
      font-size: 28px;
    }
  }
}

@media screen and(min-width: 1200px) {
  .div-banner-2 {
    min-height: 75vw;
  }
}

@media screen and(min-width: 1280px) {
  .div-banner-2 {
    min-height: 70vw;
    background-position: 85% 100%;
  }
}
@media screen and(min-width: 1440px) {
  .div-banner-2 {
    min-height: 65vw;
  }
  .banner-promotion-2 {
    img {
      margin-top: 8%;
    }
  }
  .home-text-2 {
    h1 {
      font-size: 40px;
    }
    h2 {
      font-size: 28px;
    }
    h3 {
      font-size: 30px;
    }
  }
}

@media screen and (max-width: 820px) {
  .banner-promotion-2 {
    img {
      margin-top: 5%;
      height: 48%;
    }
  }
  .div-banner-2 {
    min-height: 400px;
  }
  .button-row {
    margin-top: 30px;
  }
  .button-booking-2 {
    width: 230px;
    font-size: 18px;
  }
  .home-text-2 {
    max-width: 100%;
    margin-top: 30px;
    h1 {
      font-size: 24px;
    }
    h2 {
      font-size: 21px;
    }
    h3 {
      font-size: 22px;
    }
  }
}

@media screen and (max-width: 768px) {
  .div-banner-2 {
    min-height: 600px;
  }
}

@media screen and (max-width: 480px) {
  .banner-promotion-2 {
    img {
      margin-top: 2%;
      margin-left: -1%;
      height: 40%;
    }
  }

  .banner-flex {
    flex-grow: 1;
  }

  .div-banner-2 {
    height: 55vh;
    background-position: 85% 100%;
    min-height: 520px;
    background-size: 220%;
  }

  .home-text-2 {
    max-width: 100%;
    margin-top: 10px;
    h1 {
      font-size: 21px;
    }
    h2 {
      font-size: 17px;
    }
    h3 {
      font-size: 17px;
    }
  }

  .button-row {
    margin-top: 8%;
  }

  .button-booking-2 {
    width: 230px;
    font-size: 16px;
    padding-left: 42px;
  }
  .button-booking-img-2 {
    left: -2px;
    top: -4px;
    height: 45px !important;
    width: 45px !important;
  }
}

@media screen and (max-width: 455px) {
  .div-banner-2 {
    min-height: 470px;
  }
  .banner-promotion-2 {
    img {
      margin-top: 8%;
      margin-left: -3%;
      height: 50%;
    }
  }
  .home-text-2 {
    margin-top: 35px;
  }
  .button-row {
    margin-top: 5%;
  }
}

@media screen and (max-width: 425px) {
  .div-banner-2 {
    min-height: 420px;
    background-position: 85% 100%;
    background-size: 210%;
  }
  .banner-promotion-2 {
    img {
      margin-top: 8%;
      margin-left: -3%;
      height: 50%;
      width: 80%;
    }
  }
  .home-text-2 {
    margin-top: 20px;
    h1 {
      font-size: 20.5px;
    }
    h2 {
      font-size: 15px;
    }
    h3 {
      font-size: 16px;
    }
  }
  .button-row {
    margin-top: 5%;
  }
}

@media screen and (max-width: 375px) {
  .div-banner-2 {
    min-height: 420px;
    background-position: 85% 100%;
    background-size: 230%;
  }
  .home-text-2 {
    h1 {
      font-size: 18.5px;
    }
    h2 {
      font-size: 15px;
    }
    h3 {
      font-size: 15px;
    }
  }
  .banner-promotion-2 {
    img {
      margin-left: -1%;
      margin-top: 5%;
      width: 80%;
    }
  }
}

@media (max-width: 341px) {
  .div-banner-2 {
    min-height: 110vw;
  }
  .home-text-2 {
    h1 {
      font-size: 15px;
    }
    h2 {
      font-size: 12px;
    }
    h3 {
      font-size: 13px;
    }
  }
}

.maid-cleaning-2 {
  background-image: url('../assets/images/maid_cleaning_new2.jpg');
  background-size: cover;
  background-position: center;
  width: 100%;
}

.button-apply-for-maid {
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 9999px;
  font-size: 30px;
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 1) !important;
}

.apply-for-maid-click {
  position: absolute;
  right: 105px;
  top: 46px;
  height: 40px !important;
  width: 40px !important;
}

//end new banner 2024

.friend_avatar {
  width: 36px;
  height: 36px;
  border-radius: 36px;
  margin-left: -8px;
}
.more-friend {
  z-index: 1;
  background-color: #fb6107;
  width: 36px;
  height: 36px;
  border-radius: 36px;
  margin-left: -8px;
  color: white;
  text-align: center;
  vertical-align: middle;
  line-height: 35px;
}
.banner-promotion {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  top: 35%;
  left: 50%;

  img {
    width: 45%;
    height: 100%;
  }
}

.maid-slick {
  width: 90%;
}

.testimonial ::v-deep {
  .slick-dots:not(#_) {
    li button:before {
      background-color: #73757a;
    }
    li.slick-active button:before {
      border: none !important;
      background-color: #005dff !important;
    }
  }
  .slick-slide {
    margin: 20px;
    padding-top: 10px;
  }
  .slick-arrow::before {
    color: #005dff;
  }
}

.slick-class ::v-deep {
  .slick-prev,
  .slick-next {
    width: auto;
    height: auto;
  }
}

::v-deep .slick-arrow::before {
  font-size: 50px;
}

::v-deep .slick-prev {
  left: -70px;
}

::v-deep .slick-next {
  right: -70px;
}

.testimonial-card {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 14px;
  position: relative;
}

.testimonial-textarea-div {
  border-radius: 14px;
  background-color: white;
  box-shadow: 0px -5px 10px 2px white;
  padding-top: auto;
  padding-bottom: auto;
  height: auto;
  position: relative;
}

.testimonial-textarea {
  padding: 20px;
  height: 300px;
}

.testimonial-img {
  position: absolute;
  left: 50%;
  top: 0%;
  width: 30%;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background-color: white;
}

.testimonial-img-space {
  width: 30%;
  aspect-ratio: 2 / 1;
}

.testimonial-img img {
  aspect-ratio: 1 / 1;
  object-fit: cover;
  width: 100%;
  border-radius: 100%;
}

.testimonial-namearea {
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
}

.confetti {
  background-image: url('../assets/images/testimonial/Confetti.svg');
  background-size: cover;
}

.testimonial-double {
  position: absolute;
  width: 25px;
  aspect-ratio: 1.2 / 1;
}

.double-top {
  left: 7%;
  top: 7%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.double-bottom {
  left: 93%;
  top: 93%;
  transform: translate(-50%, -50%) scale(-1);
  -webkit-transform: translate(-50%, -50%) scale(-1);
}

@media screen and (max-width: 1199px) {
  ::v-deep .slick-prev {
    left: -50px;
  }

  ::v-deep .slick-next {
    right: -50px;
  }

  .testimonial-textarea {
    height: 320px;
  }

  .maid-slick ::v-deep {
    .slick-arrow::before {
      font-size: 30px;
    }
  }

  .banner-promotion {
    width: 100%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    top: 35%;
    left: 50%;

    img {
      width: 60%;
    }
  }
  .div-banner {
    height: 700px;
    background-image: url('../assets/images/bg-test.jpg');
    background-size: cover;
    // background-size: 100%;
    // background-position: -583px -73px;
    background-repeat: no-repeat;
  }
  .home-banner {
    margin-top: 18em;
  }
}

@media screen and (max-width: 993px) {
  .testimonial-textarea {
    height: 390px;
  }
}
@media screen and (min-width: 767px) {
  .button-booking {
    width: 250px;
  }
}
@media screen and (max-width: 767px) {
  .slick-class {
    width: 80% !important;
  }

  ::v-deep .slick-arrow::before {
    font-size: 30px !important;
  }

  ::v-deep .slick-prev {
    left: -30px;
  }

  ::v-deep .slick-next {
    right: -30px;
  }

  .testimonial-textarea {
    height: 340px;
  }
}
@media screen and (max-width: 540px) {
  .testimonial-textarea {
    height: 360px;
  }
}
@media screen and (max-width: 540px) {
  .home-banner {
    margin-top: 15em;
  }
  .button-booking {
    width: 250px;
  }
  .banner-promotion {
    top: 30%;
    left: 36%;
    padding-bottom: 37px !important;
  }
  .div-banner {
    height: 450px;
    background-image: url('../assets/images/bg-test.jpg');
    // background-size: cover;
    background-size: 280%;
    background-position: -583px -73px;
    background-repeat: no-repeat;
  }
  .button-booking {
    height: 50px;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    padding-bottom: 12px;
  }
  .div-btn-mobile {
    margin-right: auto;
    margin-left: auto;
  }
  .div-banner-2 {
    height: 480px;
  }
}
@media screen and (max-width: 529px) {
  .home-banner {
    margin-top: 15em;
  }
  .button-booking {
    width: 250px;
  }
  .banner-promotion {
    top: 30%;
    left: 36%;
    padding-bottom: 37px !important;
  }
  .div-banner {
    height: 450px;
    background-image: url('../assets/images/bg-test.jpg');
    // background-size: cover;
    background-size: 257%;
    background-position: -583px -73px;
    background-repeat: no-repeat;
  }
  .button-booking {
    height: 45px !important;
    width: 205px;
    font-size: 0.9rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    padding-bottom: 12px;
  }
  .div-btn-mobile {
    margin-right: auto;
    margin-left: auto;
  }

  .testimonial-textarea {
    height: 360px;
  }
}
@media screen and (max-width: 424px) {
  .testimonial-textarea {
    height: 430px;
  }
}
@media screen and (max-width: 350px) {
  .testimonial-textarea {
    height: 500px;
  }
}
@media screen and (max-width: 424px) {
  .home-banner {
    margin-top: 15em;
  }
  .button-booking {
    width: 250px;
  }
  .banner-promotion {
    top: 30%;
    left: 36%;
    padding-bottom: 37px !important;
  }
  .div-banner {
    height: 450px;
    background-image: url('../assets/images/bg-test.jpg');
    // background-size: cover;
    background-size: 280%;
    background-position: -583px -73px;
    background-repeat: no-repeat;
  }
  .button-booking {
    height: 45px !important;
    width: 205px;
    font-size: 0.9rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    padding-bottom: 12px;
  }
  .div-btn-mobile {
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (min-width: 320px) {
  .home-banner {
    margin-top: 8em;
  }
  .button-booking {
    width: 250px;
  }
  .banner-promotion {
    top: 30%;
    left: 36%;
    padding-bottom: 37px !important;
  }
  .div-banner {
    height: 450px;
    background-image: url('../assets/images/bg-test.jpg');
    // background-size: cover;
    background-size: 277%;
    background-position: -507px -54px;
    background-repeat: no-repeat;
  }
  .button-booking {
    height: 45px !important;
    width: 205px;
    font-size: 0.9rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    padding-bottom: 12px;
  }
  .div-btn-mobile {
    margin-right: auto;
    margin-left: auto;
  }
  .div-banner {
    min-height: 450px;
  }
}
@media screen and (min-width: 360px) {
  .home-banner {
    margin-top: 10em;
  }
  .button-booking {
    width: 250px;
  }
  .banner-promotion {
    top: 30%;
    left: 36%;
    padding-bottom: 37px !important;
  }
  .div-banner {
    height: 450px;
    background-image: url('../assets/images/bg-test.jpg');
    // background-size: cover;
    background-size: 280%;
    background-position: -579px -73px;
    background-repeat: no-repeat;
  }
  .button-booking {
    height: 45px !important;
    width: 205px;
    font-size: 0.9rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    padding-bottom: 12px;
  }
  .div-btn-mobile {
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (min-width: 374px) {
  .home-banner {
    margin-top: 10em;
  }
  .button-booking {
    width: 250px;
  }
  .banner-promotion {
    top: 30%;
    left: 36%;
    padding-bottom: 37px !important;
  }
  .div-banner {
    height: 450px;
    background-image: url('../assets/images/bg-test.jpg');
    // background-size: cover;
    background-size: 255%;
    background-position: -489px -68px;
    background-repeat: no-repeat;
  }
  .button-booking {
    height: 45px !important;
    width: 205px;
    font-size: 1rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    padding-bottom: 12px;
  }
  .div-btn-mobile {
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 414px) {
  .home-banner {
    margin-top: 10em;
  }
  .button-booking {
    width: 250px;
  }
  .banner-promotion {
    top: 30%;
    left: 36%;
    padding-bottom: 37px !important;
  }
  .div-banner {
    height: 450px;
    background-image: url('../assets/images/bg-test.jpg');
    // background-size: cover;
    background-size: 215%;
    background-position: -435px -50px;
    background-repeat: no-repeat;
  }
  .button-booking {
    height: 45px !important;
    width: 205px;
    font-size: 0.9rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    padding-bottom: 12px;
  }
  .div-btn-mobile {
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 500px) {
  .home-banner {
    margin-top: 10em;
  }
  .button-booking {
    width: 250px;
  }
  .banner-promotion {
    top: 30%;
    left: 36%;
    padding-bottom: 37px !important;
  }
  .banner-promotion img {
    width: 50%;
  }
  .div-banner {
    height: 450px;
    background-image: url('../assets/images/bg-test.jpg');
    // background-size: cover;
    background-size: 235%;
    background-position: -634px -82px;
    background-repeat: no-repeat;
  }
  .button-booking {
    height: 45px !important;
    width: 205px;
    font-size: 1rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    padding-bottom: 12px;
  }
  .div-btn-mobile {
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 768px) {
  .home-banner {
    margin-top: 30em;
  }
  .button-booking {
    width: 250px;
  }
  .banner-promotion {
    top: 40%;
    left: 36%;
    padding-bottom: 37px !important;
  }

  .div-banner {
    height: 75vh;
    background-image: url('../assets/images/bg-test.jpg');
    background-size: 276%;
    background-position: -1154px -82px;
    background-repeat: no-repeat;
  }
}
@media screen and (min-width: 1024px) {
  .home-banner {
    margin-top: 35em;
  }
  .button-booking {
    width: 280px;
  }
  .banner-promotion {
    top: 40%;
    left: 36%;
    padding-bottom: 37px !important;
  }
  .div-banner {
    height: 75vh;
    background-image: url('../assets/images/bg-test.jpg');
    background-size: 276%;
    background-position: -1550px -82px;
    background-repeat: no-repeat;
  }
  .button-booking {
    height: 45px !important;
    font-size: 1.1rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    padding-bottom: 12px;
  }
  .div-btn-mobile {
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 768px) and(min-height: 400px) {
  .div-banner {
    height: 55vh;
    background-image: url('../assets/images/bg-test.jpg');
    background-size: cover;
    background-size: 138%;
    background-position: -200px 0;
    // background-position: right;
  }
  .banner-promotion img {
    width: 45%;
  }

  .banner-promotion {
    top: 43% !important;
    left: 50% !important;
    padding-bottom: 37px;
  }
  .col-xl-3 {
    max-width: 35% !important;
  }
  .home-banner {
    width: 100%;
    position: absolute;
    bottom: 48px;
    // margin-bottom: 5em;
  }
  .margin1440 {
    margin-top: 2em;
  }
}

@media screen and (min-width: 1200px) and (min-height: 700px) {
  .div-banner {
    height: 98vh;
    background-image: url('../assets/images/bg-test.jpg');
    background-size: cover;
    background-position: 0px 0px;
    // background-position: right;
  }
  .button-booking {
    padding-left: 25px;
    font-size: 1.1rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    padding-bottom: 12px;
    width: 240px;
  }
  .banner-promotion {
    top: 43% !important;
    left: 50% !important;
    padding-bottom: 37px;
  }
  .col-xl-3 {
    max-width: 24% !important;
  }
  .home-banner {
    width: 100%;
    position: absolute;
    // bottom: 101px;
    // margin-bottom: 5em;
  }
  .margin1440 {
    margin-top: 2em;
  }
}
@media screen and(min-width: 1920px) {
  .div-banner {
    height: 98vh;
    background-image: url('../assets/images/bg-test.jpg');
    background-size: cover;
    background-position: 0px 0px;
    // background-position: right;
  }
  .button-booking {
    padding-left: 25px;
    font-size: 0.9rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    padding-bottom: 12px;
    width: 228px;
  }
  .banner-promotion[data-v-fae5bece] {
    top: 43%;
    left: 50%;
    padding-bottom: 37px;
  }
  .col-xl-3 {
    max-width: 24% !important;
  }
  .home-banner {
    width: 100%;
    position: absolute;
    bottom: 124px;
    // margin-bottom: 5em;
  }
  .margin1440 {
    margin-top: 2em;
  }
}
.promote-bg {
  width: 100%;
  height: 100%;
  @media screen and(max-width:1024px) {
    padding-top: 20px;
    background-color: #005dff !important;
  }
}

.promote-section {
  width: 95%;

  @media screen and(min-width: 1025px) {
    margin: 0 auto;
    width: 90%;
  }
  @media screen and(min-width: 1440px) {
    margin: 0 auto;
    width: 80%;
  }
}

.promote-box {
  width: 100%;
  height: 100%;
  margin-left: 5px;
  margin-right: 5px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.15), 0 0 0 0 rgba(0, 0, 0, 0.19);

  @media screen and (min-width: 425px) {
    height: 100%;
    margin-left: 5px;
    margin-right: 5px;
  }
  @media screen and (min-width: 820px) {
    height: 95%;
    margin-left: 3px;
    margin-right: 3px;
  }
  @media screen and (min-width: 1025px) {
    height: 55%;
  }
  @media screen and (min-width: 1440px) {
    height: 55%;
  }
}

.icon-container {
  padding-top: 5px;
  @media screen and(max-width: 1024px) {
    width: 48%;
  }
}

.promote-text {
  font-size: 15px;
  color: #004aad;
  font-weight: 600;
  padding-bottom: 15px;
  padding-right: 10px;
  padding-left: 10px;

  @media screen and(max-width: 1024px) {
    padding-bottom: 0px;
    font-size: 12px;
  }
}

.maid-description {
  position: relative;
  width: 85%;
  height: auto;
  min-height: 15vh;
  background-color: #ffffff;
  margin-bottom: 60px;
  border-radius: 8px;
  font-size: 12px;
  color: #004aad;
  overflow: hidden;

  @media screen and(min-width: 1440px) {
    width: 90%;
    min-height: 18vh;
  }
}

.maid-description::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background: linear-gradient(
    to right,
    #7daa35 0%,
    #7daa35 33%,
    #f3b739 33%,
    #f3b739 66%,
    #fb6107 66%,
    #fb6107 100%
  );
  border-radius: 0 0 8px 8px;
}

.maid-text {
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  font-size: 19px;
  width: 100%;
  color: #005dff;
  text-shadow: 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff,
    0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff,
    0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff,
    0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff,
    0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff,
    0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff,
    0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff,
    0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff, 0 0 7px #ffffff,
    0 0 7px #ffffff;
  text-align: center;

  @media screen and(min-width: 425px) {
    font-size: 20px;
  }
  @media screen and(min-width: 768px) {
    font-size: 25px;
  }
  @media screen and(min-width: 1440px) {
    font-size: 28px;
  }
}

.check-icon {
  height: 15px;
  max-width: 100%;
}

.maid-detail {
  width: 90%;
  padding: 25px;
  font-size: 13px;

  @media screen and(min-width: 768px) {
    font-size: 16px;
  }
  @media screen and(min-width: 1440px) {
    padding: 20px 0 20px 80px;
  }
}

.element-left-top {
  position: absolute;
  top: -30px;
  left: 5px;
  width: 40px;

  @media screen and(min-width: 768px) {
    width: 65px;
    top: -40px;
    left: 10px;
  }
  @media screen and(min-width: 1440px) {
    width: 75px;
    top: -50px;
    left: 20px;
  }
}

.element-right-bottom {
  position: absolute;
  bottom: 35px;
  right: 5px;
  width: 40px;

  @media screen and(min-width: 768px) {
    width: 65px;
    right: 10px;
    bottom: 20px;
  }
  @media screen and(min-width: 1440px) {
    width: 75px;
    right: 10px;
    bottom: 20px;
  }
}

</style>